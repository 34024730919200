import { createLocalVue, mount } from "@vue/test-utils";

import Vuex from "vuex";
import i18next from "@/i18next";
import { BImg } from "bootstrap-vue";

import Preferences from "../Preferences";

// create an extended `Vue` constructor
const localVue = createLocalVue();
localVue.use(Vuex);

localVue.component("b-img", BImg);

describe("Preferences.vue", () => {
  it("render when created ", async () => {
    let wrapper = await getWrapper();
    expect(wrapper.exists()).toBeTruthy();
    expect(wrapper.element).toMatchSnapshot();
    wrapper.destroy();
  });
});

function getWrapper() {
  let getters = {
    allLanguage: () => {
      return [
        {
          id: "en",
          name: "English"
        },
        {
          id: "fr",
          name: "French"
        }
      ];
    },
    allSections() {
      return [
        {
          id: "general",
          isEdit: false
        },
        {
          id: "professional_info",
          isEdit: false
        },
        {
          id: "learning_interest",
          isEdit: false
        },
        {
          id: "system_language",
          isEdit: false
        }
      ];
    },
    allProfile: () => {
      return {
        given_name: "World",
        family_name: "Hello",
        display_name: "hello_w",
        gender: "Female",
        email: "a@b.c",
        age: "2",
        language: "en"
      };
    }
  };
  let store = new Vuex.Store({
    getters
  });
  let wrapper = mount(Preferences, {
    propsData: {
      section: {
        id: "general",
        isEdit: false
      }
    },
    localVue,
    store,
    i18n: i18next
  });

  return wrapper;
}
